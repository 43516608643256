<template>
    <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="data"
        sort-by="id"
        class="elevation-1"
        @click:row="editItem($event)"
        no-data-text="Нет данных"
        no-results-text="Нет данных, попробуйте изменить запрос"
        :footer-props="{
            pageText: '{0} - {1} из {2}',
            itemsPerPageText: 'Показывать на странице',
            itemsPerPageAllText: 'все',
            showFirstLastPage: true,
            itemsPerPageOptions: [25, -1],
        }"
    >
        <!-- hide-default-footer -->
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Список подписок</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                            + Новая
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.name" label="Название"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.price" label="Стоимость, руб./мес." type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.count" label="Пакет смс, шт." type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.cost" label="Цена смс, руб./шт." type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                Отмена
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title>Вы действительно хотите удалить подписку?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Отмена</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ок</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="pa-2" @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            Подписки отсутствуют
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: 'subscribe',
        data: () => ({
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                    text: 'Название',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Стоимость, руб./мес.', value: 'price', sortable: false },
                { text: 'Пакет смс, шт.', value: 'count', sortable: false },
                { text: 'Цена смс, руб./шт.', value: 'cost', sortable: false },
                { text: 'Удаление', value: 'actions', sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                id: 1,
                name: 'Новая подписка',
                price: 0,
                count: 0,
                cost: 0,
            },
            defaultItem: {
                id: 1,
                name: 'Новая подписка',
                price: 0,
                count: 0,
                cost: 0,
            },
        }),
        props: {
            data: {
                type: Array,
                default: null,
            },
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Новая подписка' : 'Редактировать подписку'
            },
        },

        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        },

        created() {},

        methods: {
            editItem(item) {
                if (this.dialogDelete) return
                this.editedIndex = this.data.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.data.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm() {
                this.data.splice(this.editedIndex, 1)
                this.closeDelete()
                this.$emit('change', this.data)
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save() {
                if (this.editedIndex > -1) {
                    Object.assign(this.data[this.editedIndex], this.editedItem)
                } else {
                    if (this.data.length) {
                        this.editedItem.id = this.data[this.data.length - 1].id + 1
                    } else {
                        this.editedItem.id = 1
                    }
                    this.editedItem.price = +this.editedItem.price
                    this.editedItem.count = +this.editedItem.count
                    this.editedItem.cost = +this.editedItem.cost
                    this.data.push(this.editedItem)
                }
                this.close()
                this.$emit('change', this.data)
            },
        },
    }
</script>
