<template>
    <div class="settings">
        <div v-if="!firstLoading && !userIsPoint">
            <v-alert type="error" class="settings-alert elevation-3" :value="errorSave" :dismissible="true">
                {{ $t('settings.error_save_settings') }}
            </v-alert>

            <v-row v-if="!access" style="max-width: 2000px" class="mx-auto">
                <v-col sm="12" md="6" class="d-flex justify-center">
                    <v-card :loading="loadingAccess" align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                        <template slot="progress">
                            <v-progress-linear indeterminate absolute bottom color="deep-purple accent-4"></v-progress-linear>
                        </template>

                        <v-toolbar height="50" color="error darken-2 elevation-3" dark flat>
                            <v-toolbar-title class="overline"> Доступ к настройкам закрыт </v-toolbar-title>
                        </v-toolbar>

                        <v-row class="settings-content">
                            <v-col cols="12">
                                <p class="my-0 text-wrap">
                                    <v-icon color="error" class="mr-1">mdi-information-outline</v-icon>
                                    Для доступа к настройкам введите пароль.
                                </p>
                            </v-col>

                            <v-col cols="6">
                                <v-text-field autocomplete="new-password" placeholder="" prepend-icon="mdi-key" label="Пароль" v-model="settings_pass_value" @click:append="settings_pass_show = !settings_pass_show" v-on:keyup.enter="onClickSettingsPassEnter" hide-details></v-text-field>
                            </v-col>

                            <v-col cols="6" class="d-flex justify-center">
                                <v-btn class="ml-4" color="success" @click="onClickSettingsPassEnter">
                                    <v-icon dense class="mr-1">mdi-lock-open-outline</v-icon>
                                    Открыть
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-if="access && !$store.state.loading" style="max-width: 2000px" class="mx-auto">

                <v-col sm="12" md="3">
                    <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                        <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                            <v-toolbar-title class="overline"> Пароль для доступа к настройкам </v-toolbar-title>
                        </v-toolbar>

                        <v-row class="settings-content">
                            <v-col cols="12" class="d-flex flex-column">
                                <p class="my-0 text-wrap">Установите пароль для ограничения доступа к странице настроек. Если пароль задать пустым, то блокировки страницы не будет.</p>
                                <v-text-field
                                    placeholder=""
                                    :append-icon="settings_pass_show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="settings_pass_show ? 'text' : 'password'"
                                    label="Пароль"
                                    v-model="settings_pass_value"
                                    @click:append="settings_pass_show = !settings_pass_show"
                                    prepend-icon="mdi-key"
                                    hide-details
                                ></v-text-field>
                            </v-col>

                            <v-btn class="ml-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                Сохранить
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col sm="12" md="3">
                    <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                        <v-toolbar height="50" color="purple darken-2 elevation-3" dark flat>
                            <v-toolbar-title class="overline"> Доступ к darall.pro </v-toolbar-title>
                        </v-toolbar>

                        <v-row class="settings-content">
                            <v-col cols="12" class="d-flex flex-column">
                                <p class="my-0 text-wrap">Для смены пароля введите текущий пароль и дважды новый пароль в соответствующие поля.</p>
                                <v-text-field
                                    autocomplete="off"
                                    name="password-old"
                                    placeholder=""
                                    hide-details
                                    :append-icon="pass1_show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="pass1_show ? 'text' : 'password'"
                                    label="Текущий пароль"
                                    v-model="pass1_value"
                                    prepend-icon="mdi-key"
                                    @click:append="pass1_show = !pass1_show">
                                </v-text-field>

                                <v-text-field
                                    autocomplete="off"
                                    name="password-new"
                                    placeholder=""
                                    hide-details
                                    :append-icon="pass2_show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="pass2_show ? 'text' : 'password'"
                                    label="Новый пароль"
                                    v-model="pass2_value"
                                    prepend-icon="mdi-key"
                                    @click:append="pass2_show = !pass2_show">
                                </v-text-field>

                                <v-text-field
                                    autocomplete="off"
                                    name="password-again"
                                    placeholder=""
                                    hide-details
                                    :append-icon="pass3_show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="pass3_show ? 'text' : 'password'"
                                    label="Повтор нового пароля"
                                    v-model="pass3_value"
                                    prepend-icon="mdi-key"
                                    @click:append="pass3_show = !pass3_show">
                                </v-text-field>
                            </v-col>

                            <v-btn class="ml-4" color="success" @click="onClickPassSave" :disabled="updatePassFlag" :loading="updatePassFlag">
                                <v-icon dense class="mr-1">mdi-refresh</v-icon>
                                Изменить
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col sm="12" md="3">
                    <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                        <v-toolbar height="50" color="blue darken-1 elevation-3" dark flat>
                            <v-toolbar-title class="overline"> Доступ к сервису Mynex.ru </v-toolbar-title>
                        </v-toolbar>

                        <v-row class="settings-content">
                            <v-col cols="12" class="d-flex flex-column">
                                <p class="my-0 text-wrap">Чтобы установить или обновить доступ, заполните логин и пароль сервиса Mynex.ru и нажмите "Обновить".</p>
                                <v-text-field placeholder="" hide-details label="Логин" v-model="mynexLogin" prepend-icon="mdi-account"></v-text-field>
                                <v-text-field placeholder="" hide-details :append-icon="pass5_show ? 'mdi-eye' : 'mdi-eye-off'" :type="pass5_show ? 'text' : 'password'" label="Пароль" v-model="mynexPass" prepend-icon="mdi-key" @click:append="pass5_show = !pass5_show"></v-text-field>
                            </v-col>

                            <v-btn class="ml-4" color="success" @click="onClickMynexSave" :disabled="updateMynexFlag" :loading="updateMynexFlag">
                                <v-icon dense class="mr-1">mdi-refresh</v-icon>
                                Обновить
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                        <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                            <v-toolbar-title class="overline">
                                {{ $t('settings.delivery') }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="onClickSave" :disabled="!saveTimer">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-row class="settings-content">
                            <v-col cols="12">
                                <p class="my-0 mt-4">1. Цена доставки по геозонам (переместите Точку в соответствующий ценовой блок):</p>
                            </v-col>

                            <v-col cols="12" v-if="newPoints.length">
                                <v-card class="mx-auto" max-width="500">
                                    <v-toolbar color="teal" dark height="40">
                                        <v-toolbar-title>Нераспределенные точки ({{ newPoints.length }})</v-toolbar-title>
                                    </v-toolbar>

                                    <v-list dense>
                                        <draggable class="list-group" :list="newPoints" group="people" @change="onChangePrice">
                                            <v-list-item v-for="point in newPoints" :key="'new-' + point.id" @click="onClickAddress(point.address)">
                                                <v-list-item-content class="d-block">
                                                    <b>{{ point.name }}</b> {{ ' - ' + point.address }}
                                                </v-list-item-content>
                                            </v-list-item>
                                        </draggable>
                                    </v-list>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="3" v-for="(price, i) of prices_full" :key="i">
                                <v-card class="mx-auto" max-width="500">
                                    <v-toolbar color="teal" dark height="40">
                                        <v-toolbar-title>Цена {{ i + 1 }} ({{ price.length }})</v-toolbar-title>
                                    </v-toolbar>

                                    <v-list dense>
                                        <draggable class="list-group" :list="price" group="people" @change="onChangePrice">
                                            <v-list-item v-for="(point, j) in price" :key="i + '-' + j + '-' + point.id" @click="onClickAddress(point.address)">
                                                <v-list-item-content class="d-block">
                                                    <b>{{ point.name }}</b> {{ ' - ' + point.address }}
                                                </v-list-item-content>
                                            </v-list-item>
                                        </draggable>
                                    </v-list>
                                </v-card>
                            </v-col>

                            <v-col cols="12"> </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p>2. Цена доставки, если адрес доставки не соответствует ни одной геозоне:</p>
                                <v-text-field class="py-2" style="max-width: 300px" v-model="settings.price_courier_default" label="Цена курьера, руб." @change="onChangePriceDefault()" hide-details></v-text-field>
                                <v-text-field class="py-2" style="max-width: 300px" v-model="settings.price_point_default" label="Цена точки, руб." @change="onChangePriceDefault()" hide-details></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6" class="settings-test">
                                <p>3. Тест цены доставки в зависимости от адреса:</p>
                                <div class="d-flex flex-row">
                                    <v-text-field class="py-0" style="max-width: 300px" v-model="address" label="Адрес доставки" v-on:keyup.enter="onClickPriceGet" @change="onChangeAddress()" hide-details></v-text-field>
                                    <v-btn class="ml-4" icon @click="onClickPriceGet">
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </div>
                                <p class="pt-3 my-0 text-caption">{{ test_address_result }}</p>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p>4. Установить добавочное время доставки:</p>

                                <div class="d-flex flex-row">
                                    <div class="d-flex align-center">
                                        <v-text-field placeholder="" label="Время (мин)" v-model="deliveryDelay" hide-details></v-text-field>
                                        <v-btn class="ml-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                            <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>

                                <p v-if="settings.deliveryDelay > 0" class="my-0 mt-4">
                                    <v-icon color="warning" class="mr-1">mdi-information-outline</v-icon>
                                    На странице местоположения курьера будет отображен текст:
                                </p>
                                <p v-else class="my-0 mt-4">
                                    <v-icon color="warning" class="mr-1">mdi-information-outline</v-icon>
                                    На странице местоположения курьера не будет дополнительного текста.
                                </p>
                                <p v-if="settings.deliveryDelay > 0" class="mt-1 pa-2 settings-test">{{ `Доставка может быть дольше на ${settings.deliveryDelay} мин., более точное время или причины можно уточнить у курьера.\nПриносим извинения за задержку 😇` }}</p>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p>5. Установить часовой пояс:</p>
                                <div class="d-flex flex-row">
                                    <div class="d-flex align-center flex-column">
                                        <v-text-field placeholder="" type="text" label="Город" v-model="settings_city_value" hide-details></v-text-field>
                                        <v-text-field placeholder="" type="text" label="Часовой пояс (строка)" v-model="settings_tz_string_value" hide-details></v-text-field>
                                        <v-text-field placeholder="" type="text" label="Часовой пояс (сдвиг)" v-model="settings_tz_value" hide-details></v-text-field>

                                        <v-btn class="ma-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                            <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p>6. Показывать столбец источник (реклама):</p>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-column">
                                        <v-switch v-model="settings.marketingSourceView" label="Включить" @change="onChangeSettings"></v-switch>
                                    
                                        <v-btn class="ma-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                            <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p>7. Профили стоимости и времени доставки:</p>
                                <div class="d-flex flex-row">
                                    <div class="d-flex align-center flex-column">

                                        <table width="460" border="0" cellspacing="2" class="t-distance">
                                            <tbody align="center">
                                                <tr style="background-color: #ddd">
                                                    <th colspan="4">Выберите необходимый профиль и сохраните</th>
                                                </tr>
                                                <tr v-for="(profile, i) in settings.distanceProfiles" :key="'dp-' + i">
                                                    <td colspan="2" width="240" align="left">
                                                        <div @click="onClickProfileEdit(i)" class="profile-clickable">
                                                            <v-icon v-if="profile.isSelected" class="mr-1" color="green">mdi-checkbox-marked</v-icon>
                                                            <span v-if="profile.isSelected"><b>{{ profile.name }}</b></span>
                                                            <span v-else>{{ profile.name }}</span>
                                                        </div>
                                                    </td>

                                                    <v-tooltip v-if="!profile.isSelected" bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <td class="t-button" :class="profile.isSelected ? 't-disabled': 't-red'" @click.stop v-on="on" @click="onClickProfileDelete(i)">
                                                                <v-icon color="white" small>mdi-delete</v-icon>
                                                            </td>
                                                        </template>
                                                        Удалить профиль
                                                    </v-tooltip>
                                                    <td v-else class="t-button t-disabled" @click="onClickProfileDelete(i)">
                                                        <v-icon color="white" small>mdi-delete</v-icon>
                                                    </td>

                                                    <v-tooltip v-if="!profile.isSelected" bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <td class="t-button" :class="profile.isSelected ? 't-disabled': 't-blue'" @click.stop v-on="on" @click="onClickProfileSelect(i)">
                                                                <v-icon color="white" small>mdi-check</v-icon>
                                                                Выбрать
                                                            </td>
                                                        </template>
                                                        Активировать профиль
                                                    </v-tooltip>
                                                    <td v-else class="t-button t-disabled" @click="onClickProfileSelect(i)">
                                                        <v-icon color="white" small>mdi-check</v-icon>
                                                        Выбрать
                                                    </td>
                                                </tr>

                                                <tr style="background-color: #ddd">
                                                    <td colspan="3"></td>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <td class="t-button t-green" @click.stop v-on="on" @click="onClickProfileCopy">
                                                                <v-icon color="white" small>mdi-plus</v-icon>
                                                                Копировать
                                                            </td>
                                                        </template>
                                                        Создать новый профиль как копию текущего
                                                    </v-tooltip>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <v-btn class="ma-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                            <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p>8. Стоимость доставки в зависимости от дистанции:</p>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-column">
                                        <v-switch v-model="settings.priceByDistance" label="Включить" @change="onChangePrice"></v-switch>
                                        <v-switch :disabled="!settings.priceByDistance" v-model="settings.distanceByRoute" :label="`Дистанция по маршруту ${ settings.distanceByRoute ? 'включена' : 'отключена' }`" @change="onChangeRoute"></v-switch>
                                    </div>
                                </div>

                                <div class="d-flex flex-row">
                                    <div class="d-flex align-center flex-column">
                                        <table width="460" border="0" cellspacing="2" class="t-distance">
                                            <colgroup style="background-color: #fff">
                                                <col />
                                                <col />
                                                <col />
                                                <col style="background-color: #eee" />
                                            </colgroup>

                                            <tbody align="center">
                                                <tr style="background-color: #ddd">
                                                    <th rowspan="2">Дистанция от, м</th>
                                                    <th colspan="2">Стоимость, руб.</th>
                                                    <th rowspan="2"></th>
                                                </tr>

                                                <tr style="background-color: #ddd">
                                                    <th>для курьера</th>
                                                    <th>для точки</th>
                                                </tr>

                                                <tr v-for="(price_value, i) in settings.distancePrices" :key="'dp-' + i">
                                                    <td>{{ price_value[0] }}</td>
                                                    <td>{{ price_value[1] }}</td>
                                                    <td>{{ price_value[2] }}</td>
                                                    <td class="t-button t-blue" @click="onClickPriceChange(i)">
                                                        <v-icon color="white" small>mdi-pencil-outline</v-icon>
                                                        Изменить
                                                    </td>
                                                </tr>

                                                <tr style="background-color: #ddd">
                                                    <td colspan="3"></td>
                                                    <td class="t-button t-green" @click="onClickPriceAdd">
                                                        <v-icon color="white" small>mdi-plus</v-icon>
                                                        Добавить
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <v-btn class="ma-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                            <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p>9. Установить время забора и доставки заказа:</p>
                                <v-text-field class="py-2" style="max-width: 300px" label="Время забора, мин" v-model="settings.timeTake" hide-details></v-text-field>
                                <v-text-field class="py-2" style="max-width: 300px" label="Время доставки, мин" v-model="settings.timeDelivery" hide-details></v-text-field>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-column">
                                        <v-switch v-model="settings.timesByDistance" label="В зависимости от дистанции" @change="onChangeTimes"></v-switch>
                                    </div>
                                </div>

                                <div class="d-flex flex-row">
                                    <div class="d-flex align-center flex-column">
                                        <table width="460" border="0" cellspacing="2" class="t-distance">
                                            <colgroup style="background-color: #fff">
                                                <col />
                                                <col />
                                                <col />
                                                <col style="background-color: #eee" />
                                            </colgroup>

                                            <tbody align="center">

                                                <tr style="background-color: #ddd">
                                                    <th rowspan="2">Дистанция от, м</th>
                                                    <th colspan="2">Время, мин</th>
                                                    <th rowspan="2"></th>
                                                </tr>

                                                <tr style="background-color: #ddd">
                                                    <th>Забор</th>
                                                    <th>Доставка</th>
                                                </tr>

                                                <tr v-for="(time_value, i) in settings.distanceTimes" :key="'dp-' + i">
                                                    <td>{{ time_value[0] }}</td>
                                                    <td>{{ time_value[1] }}</td>
                                                    <td>{{ time_value[2] }}</td>
                                                    <td class="t-button t-blue" @click="onClickTimesChange(i)">
                                                        <v-icon color="white" small>mdi-pencil-outline</v-icon>
                                                        Изменить
                                                    </td>
                                                </tr>

                                                <tr style="background-color: #ddd">
                                                    <td colspan="3"></td>
                                                    <td class="t-button t-green" @click="onClickTimesAdd">
                                                        <v-icon color="white" small>mdi-plus</v-icon>
                                                        Добавить
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        <v-btn class="ma-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                            <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                            Сохранить
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <!-- Оставлю данный input для демо ЛК и Darall доставка -->
                            <v-col cols="12" sm="12" md="6" lg="6" v-if="this.$store.state.user.organizationId === 'cbc61606-1a9d-481b-8344-40ce98c4305e' || this.$store.state.user.organizationId === '3b31d06f-6efa-44c6-8df2-e809022b7182'">
                                <v-text-field class="py-2" style="max-width: 200px" label="Сумма заезда, руб" v-model="settings.extraPointFee" hide-details></v-text-field>

                                <v-btn class="ma-4" color="success" @click="onClickSettingsSave" :disabled="updateSettings" :loading="updateSettings">
                                    <v-icon dense class="mr-1">mdi-content-save-outline</v-icon>
                                    Сохранить
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col sm="12" md="6" v-if="current_point">
                    <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                        <v-toolbar height="50" color="purple darken-2 elevation-3" dark flat>
                            <v-toolbar-title class="overline">
                                {{ $t('settings.subscribe') }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="onClickSubscribeSave" :disabled="!saveTimer && !savePointsFlag">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-row class="settings-content">
                            <v-col cols="12">
                                <v-switch v-model="settings.subscribe_enable" label="Включить модуль подписки" @change="onChangeSubscribe"></v-switch>

                                <div v-if="settings.subscribe_enable">
                                    <subscribe-table :data="subscribe_data" @change="onChangeSubscribeData"></subscribe-table>

                                    <v-tabs v-model="tab_point" show-arrows background-color="transparent" color="blue darken-3" grow class="mt-3">
                                        <v-tabs-slider color="blue darken-1"></v-tabs-slider>

                                        <v-tab v-for="(point, i) in points" :key="i" :href="'#tab_point-' + i" @change="pointSelect(point)">
                                            <v-badge v-if="point.settings.subscribe_enable" color="blue darken-1" dot inline left> {{ i + 1 }}. {{ point.name }} </v-badge>
                                            {{ point.settings.subscribe_enable ? '' : i + 1 + '. ' + point.name }}
                                        </v-tab>
                                    </v-tabs>

                                    <v-card flat>
                                        <v-card-text class="settings-content">
                                            <v-row class="justify-center">
                                                <v-col cols="6" sm="6" md="6">
                                                    <v-switch v-model="current_point.settings.subscribe_enable" label="Включить подписку" @change="updatePoints"></v-switch>
                                                </v-col>

                                                <v-col cols="6" sm="6" md="6">
                                                    <v-select
                                                        class="mx-2 flex-grow-0"
                                                        style="width: 220px"
                                                        no-data-text="Нет данных"
                                                        @change="updatePoints"
                                                        :items="settings.subscribes"
                                                        item-text="name"
                                                        item-value="id"
                                                        name="subscribe"
                                                        v-model="current_point.settings.subscribe_id"
                                                        label="Выбрать подписку"
                                                        hide-details
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                </v-col>

                <v-col cols="12" md="6" v-if="current_point">
                    <v-card align="center" justify="center" class="analytics-card mx-auto elevation-6">
                        <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                            <v-toolbar-title class="overline"> Сервис СМС рассылки </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="onClickSavePoints" :disabled="!savePointsFlag">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-card-text>
                            <v-tabs v-model="tab_point" show-arrows background-color="transparent" color="blue darken-3" grow>
                                <v-tabs-slider color="blue darken-1"></v-tabs-slider>
                                <v-tab v-for="(point, i) in points" :key="i" :href="'#tab_point-' + i" @change="pointSelect(point)">
                                    <v-badge v-if="point.settings.sms_enable" color="blue darken-1" dot inline left> {{ i + 1 }}. {{ point.name }} </v-badge>
                                    {{ point.settings.sms_enable ? '' : i + 1 + '. ' + point.name }}
                                </v-tab>
                            </v-tabs>

                            <v-card flat>
                                <v-card-text class="settings-content">
                                    <v-row class="align-center">
                                        <v-col cols="6" sm="6" md="6">
                                            <v-switch v-model="current_point.settings.sms_enable" label="Включить сервис" @change="updatePoints" hide-details></v-switch>
                                        </v-col>

                                        <v-col cols="6" sm="6" md="6" v-if="current_point.settings.sms_enable">
                                            <v-select no-data-text="Нет данных" @change="smsServiceChange" :items="sms_types" prepend-icon="mdi-mail" item-text="name" v-model="current_point.settings.sms_type" label="Выбрать сервис" hide-details></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row class="align-center" v-if="current_point.settings.sms_enable">
                                        <v-col cols="12" v-if="current_point.settings.sms_type == 'sms_ru'">
                                            <v-text-field placeholder="" label="Идентификатор" prepend-icon="mdi-key" type="text" v-model="current_point.settings.sms_id" @change="updatePoints" hide-details></v-text-field>
                                        </v-col>

                                        <v-col cols="12" v-if="current_point.settings.sms_type == 'mts_exolve'">
                                            <v-textarea rows="3" placeholder="" label="apiKey" prepend-icon="mdi-key" type="text" v-model="current_point.settings.sms_id" @change="updatePoints" hide-details></v-textarea>
                                        </v-col>

                                        <v-col cols="6" v-if="current_point.settings.sms_type == 'sendmarket' || current_point.settings.sms_type == 'smsc' || current_point.settings.sms_type == 'sms_mts'">
                                            <v-text-field placeholder="" label="Логин" prepend-icon="mdi-account" type="text" v-model="current_point.settings.sms_login" @change="updatePoints" hide-details></v-text-field>
                                        </v-col>

                                        <v-col cols="6" v-if="current_point.settings.sms_type == 'sendmarket' || current_point.settings.sms_type == 'smsc' || current_point.settings.sms_type == 'sms_mts'">
                                            <v-text-field
                                                placeholder=""
                                                hide-details
                                                :append-icon="pass4_show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="pass4_show ? 'text' : 'password'"
                                                label="Пароль"
                                                v-model="current_point.settings.sms_password"
                                                prepend-icon="mdi-key"
                                                @click:append="pass4_show = !pass4_show"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col v-if="current_point.settings.sms_type !== 'mts_exolve'" cols="6">
                                            <span class="d-block pt-5"
                                                >Баланс: <b>{{ sms_balance }}</b></span
                                            >
                                        </v-col>

                                        <v-col :cols="current_point.settings.sms_type == 'mts_exolve' ? 12 : 6">
                                            <v-text-field label="Отправитель" id="sender" name="sender" prepend-icon="mdi-message" type="text" v-model="current_point.settings.sms_name" @change="updatePoints" hide-details></v-text-field>
                                            <span class="d-block pt-5">Важно! Строка <b>отправитель</b> должна быть зарегистрирована у смс-оператора, быть без пробелов и состоять только из латинских символов.</span>
                                            <span v-if="current_point.settings.sms_type == 'mts_exolve'" class="d-block pt-5">Если нет буквенного отправителя, то можно указать номер телефона (11 цифр, без пробелов и тире, начиная с 7), номер должен существовать как отправитель в личном кабинете MTS Exolve.</span>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-checkbox v-model="allPointsFlag" label="Применить для всех" @change="updatePoints"></v-checkbox>

                                            <v-switch v-model="current_point.settings.msgBotEnabled" label="Отправлять в Телеграм-бот" @change="updatePoints" hide-details></v-switch>

                                            <v-row class="align-center" v-if="current_point.settings.msgBotEnabled">
                                                <v-col cols="6">
                                                    <v-select no-data-text="Нет данных" @change="msgBotServiceChange" :items="['taptima', 'arkSoftware']" prepend-icon="mdi-mail" item-text="name" v-model="current_point.settings.msgBotType" label="Выбрать сервис" hide-details></v-select>
                                                </v-col>

                                                <v-col cols="6">
                                                    <v-text-field placeholder="" label="Токен (Api Key)" prepend-icon="mdi-key" type="text" v-model="current_point.settings.msgBotToken" @change="updatePoints" hide-details></v-text-field>
                                            </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header> Тестовая отправка сообщения </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-text-field class="px-4" label="Номер телефона" id="subscriber" name="subscriber" @change="validPhone(sms_subscriber)" type="number" v-model="sms_subscriber" hide-details></v-text-field>
                                                        <v-textarea rows="2" class="px-4" label="Текст сообщения" id="message" name="message" type="text" v-model="sms_message" hide-details></v-textarea>
                                                        <v-btn color="#4459cf" class="ma-4" style="color: white !important" :disabled="sms_message.length < 1 || sms_subscriber.length <= 10" @click="sendMessage(current_point)"
                                                            ><v-icon style="color: white !important" class="pr-2">mdi-send</v-icon>Отправить</v-btn
                                                        >
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6" v-if="current_point">
                    <v-card align="center" justify="center" class="analytics-card mx-auto elevation-6">
                        <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                            <v-toolbar-title class="overline"> Система отзывов гостей </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="onClickSavePoints" :disabled="!savePointsFlag">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-card-text>
                            <v-list-item-group>
                                <v-list-item-title>Рассылка СМС по подписке</v-list-item-title>

                                <v-tabs v-model="tab_point" show-arrows background-color="transparent" color="blue darken-3" grow>
                                    <v-tabs-slider color="blue darken-1"></v-tabs-slider>

                                    <v-tab v-for="(point, i) in points" :key="i" :href="'#tab_point-' + i" @change="pointSelect(point)">
                                        <v-badge v-if="point.settings.review_enable" color="blue darken-1" dot inline left> {{ i + 1 }}. {{ point.name }} </v-badge>
                                        {{ point.settings.review_enable ? '' : i + 1 + '. ' + point.name }}
                                    </v-tab>
                                </v-tabs>

                                <v-card flat>
                                    <v-card-text class="settings-content">
                                        <v-row class="align-center">
                                            <v-col cols="4" sm="4" md="4">
                                                <v-switch v-model="current_point.settings.review_enable" label="Включить рассылку" @change="updatePoints"></v-switch>
                                            </v-col>

                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field width="50%" label="Отсрочка после доставки заказа, мин." id="review_delay" name="review_delay" prepend-icon="mdi-timer-sand" type="number" v-model="current_point.settings.review_delay" @input="updatePoints" hide-details></v-text-field>
                                            </v-col>

                                            <v-col cols="4" sm="4" md="4">
                                                <v-text-field width="50%" label="Тема оформления" id="name_style" name="name_style" prepend-icon="mdi mdi-format-color-fill" type="text" v-model="current_point.settings.name_style" @input="updatePoints" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-text-field
                                            v-model="current_point.settings.sms_text_review"
                                            :rules="smsMessageRules"
                                            counter="70"
                                            :counter-value="v => v.length + 27"
                                            label="Текст сообщения"
                                            prepend-icon="mdi-message-reply-text-outline"
                                            suffix="https://darall.pro/r#12345"
                                            @input="updatePoints"
                                        ></v-text-field>

                                        <v-row class="align-center">
                                            <v-col cols="6" sm="6" md="6">
                                                <v-menu ref="menu_time_start" v-model="menu_time_start" :close-on-content-click="false" :nudge-right="40" :return-value.sync="current_point.settings.start_time_work" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field width="50%" v-model="current_point.settings.start_time_work" label="Время начала работы точки" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" @change="updatePoints"></v-text-field>
                                                    </template>
                                                    <v-time-picker v-if="menu_time_start" v-model="current_point.settings.start_time_work" full-width format="24hr" color="indigo" scrollable @input="$refs.menu_time_start.save(current_point.settings.start_time_work)"></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="6">
                                                <v-menu ref="menu_time_end" v-model="menu_time_end" :close-on-content-click="false" :nudge-right="40" :return-value.sync="current_point.settings.end_time_work" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field width="50%" v-model="current_point.settings.end_time_work" label="Время окончания работы точки" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" @input="updatePoints"></v-text-field>
                                                    </template>
                                                    <v-time-picker v-if="menu_time_end" v-model="current_point.settings.end_time_work" full-width format="24hr" color="indigo" scrollable @input="$refs.menu_time_end.save(current_point.settings.end_time_work)"></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <p style="margin-top: 16px">Ссылки на популярные отзовики:</p>
                                        <v-text-field label="Telegram" id="telegram" name="telegram" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_telegram" @change="updatePoints"></v-text-field>
                                        <v-text-field label="Яндекс Дзен" id="zen" name="zen" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_zen" @change="updatePoints"></v-text-field>
                                        <!-- <v-text-field label="Instagram" id="instagram" name="instagram" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_instagram" @change="updatePoints"></v-text-field>
                                        <v-text-field label="Tik-Tok" id="tiktok" name="tiktok" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_tiktok" @change="updatePoints"></v-text-field> -->
                                        <v-text-field label="VL.RU" id="vlru" name="vlru" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_vlru" @change="updatePoints"></v-text-field>
                                        <v-text-field label="Tripadvisor" id="tripadvisor" name="tripadvisor" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_trip" @change="updatePoints"></v-text-field>
                                        <v-text-field label="Справочник 2Gis" id="gis" name="gis" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_2gis" @change="updatePoints"></v-text-field>
                                        <v-text-field label="Яндекс Карты" id="yandex" name="yandex" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_yandex" @change="updatePoints"></v-text-field>
                                        <v-text-field label="Google Карты" id="google" name="google" prepend-icon="mdi-link" type="text" v-model="current_point.settings.review_google" @change="updatePoints"></v-text-field>

                                        <p class="d-flex my-0 text-wrap">
                                            <v-icon color="warning" class="mr-2">mdi-information-outline</v-icon>
                                            Если ссылка не указана, то у гостя не будет отображаться кнопка для перехода на этот отзовик.
                                        </p>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-group>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6" v-if="current_point">
                    <v-card align="center" justify="center" class="analytics-card mx-auto elevation-6">
                        <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                            <v-toolbar-title class="overline"> Местоположение курьеров для гостей </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="onClickSavePoints" :disabled="!savePointsFlag">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-list-item-group>
                                <v-list-item-title>Рассылка СМС по подписке</v-list-item-title>

                                <v-tabs v-model="tab_point" show-arrows background-color="transparent" color="blue darken-3" grow>
                                    <v-tabs-slider color="blue darken-1"></v-tabs-slider>

                                    <v-tab v-for="(point, i) in points" :key="i" :href="'#tab_point-' + i" @change="pointSelect(point)">
                                        <v-badge v-if="point.settings.position_enable" color="blue darken-1" dot inline left> {{ i + 1 }}. {{ point.name }} </v-badge>
                                        {{ point.settings.position_enable ? '' : i + 1 + '. ' + point.name }}
                                    </v-tab>
                                </v-tabs>

                                <v-card flat>
                                    <v-card-text class="settings-content">
                                        <v-row class="align-center">
                                            <v-col cols="12" sm="6" md="6">
                                                <v-switch v-model="current_point.settings.position_enable" label="Включить рассылку" @change="updatePoints"></v-switch>
                                            </v-col>

                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field label="Отсрочка после забора заказа, мин." id="position_delay" name="position_delay" prepend-icon="mdi-timer-sand" type="number" v-model="current_point.settings.position_delay" @input="updatePoints" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-text-field
                                            v-model="current_point.settings.sms_text_position"
                                            :rules="smsMessageRules"
                                            counter="70"
                                            :counter-value="v => v.length + 27"
                                            label="Текст сообщения"
                                            prepend-icon="mdi-message-reply-text-outline"
                                            suffix="https://darall.pro/p#12345"
                                            @input="updatePoints"
                                        ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-list-item-group>
                        </v-card-text>
                    </v-card>

                    <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4 mt-4">
                        <v-toolbar height="50" color="purple darken-2 elevation-3" dark flat>
                            <v-toolbar-title class="overline">
                                {{ $t('settings.services') }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-row class="settings-content">
                            <v-col cols="12">
                                <v-switch v-model="settings.services_enable" label="Включить модуль сервисов" @change="onChangeServices"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    
                </v-col>
            </v-row>

            <v-row v-if="access && $store.state.loading" style="max-width: 2000px" class="mx-auto"> Подождите, идет загрузка ... </v-row>

            <span class="pb-16 d-block" />
        </div>

        <v-row v-if="userIsPoint || (access && !$store.state.loading)" style="max-width: 2000px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4 text-center">
                    <v-toolbar height="50" color="#C85353" dark flat>
                        <v-toolbar-title class="overline"> Настройка страницы отзывов </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn plain text @click.stop="review_page = true"> Страница отзыва </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-card flat>
                            <v-card-text class="settings-content">
                                <v-row align="center" v-if="!userIsPoint">
                                    <v-col cols="12">
                                        <v-select v-model="qa_point_id" persistent-placeholder dense :items="points" item-value="id" prepend-icon="mdi-map-marker" @change="pointSelectId($event)">
                                            <template slot="selection" slot-scope="data">
                                                <b>{{ data.item.name }}</b>
                                                <span class="ml-3 text-caption">{{ data.item.address }}</span>
                                            </template>
                                            <template slot="item" slot-scope="data">
                                                <b>{{ data.item.name }}</b>
                                                <span class="ml-3 text-caption">{{ data.item.address }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="mx-auto" align="center" justify="center">
                                    <v-col cols="12" sm="1" md="1">
                                        <v-row align="center" justify="center">
                                            <p>Еда/Товар</p>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2">
                                        <v-row align="center" justify="center">
                                            <p>Доставка</p>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="5" md="5"> </v-col>

                                    <v-col cols="12" sm="2" md="2" align-self="end">
                                        <v-row align="center" justify="center">
                                            <p>Показывать</p>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2" align-self="end">
                                        <v-row align="center" justify="center">
                                            <p>Удалить</p>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row align="center" justify="center" class="my-auto mx-auto" v-for="quick_answer in quick_answers" :key="quick_answer.id">
                                    <v-col cols="12" sm="1" md="1">
                                        <v-row align="center" justify="center">
                                            <v-checkbox color="gray" @change="quickAnswerUpdate(quick_answer)" v-model="quick_answer.food"></v-checkbox>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2">
                                        <v-row align="center" justify="center">
                                            <v-checkbox color="gray" @change="quickAnswerUpdate(quick_answer)" v-model="quick_answer.delivery"></v-checkbox>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="5" md="5">
                                        <v-text-field v-model="quick_answer.name" color="#6691FF" prepend-icon="mdi-message-reply-text-outline" @change="quickAnswerUpdate(quick_answer)"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2">
                                        <v-row align="center" justify="center">
                                            <v-btn icon :elevation="quick_answer.isShowed ? 0 : 4" @click="quickAnswerChangeIsShowed(quick_answer)">
                                                <v-icon class="mx-auto" x-large :color="quick_answer.isShowed === 1 ? 'black' : 'white'">mdi-check</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="2" md="2">
                                        <v-row align="center" justify="center">
                                            <v-btn icon @click="quickAnswerDelete(quick_answer)">
                                                <v-icon class="mx-auto" x-large color="red">mdi-close-circle</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <v-row align="center" justify="center" v-if="quick_answers.length <= 5">
                                    <v-btn color="black" plain absolute @click="quickAnswerCreate()">
                                        Добавить
                                        <v-icon x-large color="#77DD00">mdi-plus-circle</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="review_page" width="50%">
            <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4 text-center review-page">
                <v-toolbar height="50" color="#C85353" dark flat>
                    <v-toolbar-title class="overline"> Страница отзыва </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn plain text @click.stop=";(review_page = false), (review_page_text = '')"> Закрыть </v-btn>
                </v-toolbar>
                <v-card-text>
                    <div class="quick-answers">
                        <span v-for="quick_answer in quick_answers" :key="quick_answer.id">
                            <v-btn small v-if="quick_answer.isShowed" @click="clickQuickAnswer(quick_answer)" :color="color_background[parseInt(parseInt(quick_answer.id % 10) % 4)]" :elevation="quick_answer.isPressed ? 4 : 0">
                                {{ quick_answer.name }}
                            </v-btn>
                        </span>
                    </div>
                    <v-textarea solo v-model="review_page_text" label="Пожалуйста, напишите что вам не понравилось"></v-textarea>
                    <button class="btn-page">Оставить отзыв</button>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPrice" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ dialogPriceHeader }}
                </v-card-title>

                <v-card-text class="py-4">
                    <v-text-field placeholder="" hide-details type="number" min="0" label="Дистанция, м" v-model="dialogPriceDistance"></v-text-field>
                    <v-text-field placeholder="" hide-details type="number" min="0" label="Стоимость для курьера, руб." v-model="dialogPriceCourier"></v-text-field>
                    <v-text-field placeholder="" hide-details type="number" min="0" label="Стоимость для точек, руб." v-model="dialogPricePoint"></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="onClickPriceSave">Сохранить</v-btn>
                    <v-btn v-if="dialogPriceChange" class="mx-2" color="error" text @click="onClickPriceDelete">Удалить</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogPrice = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTimes" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ dialogTimesHeader }}
                </v-card-title>

                <v-card-text class="py-4">
                    <v-text-field placeholder="" hide-details type="number" min="0" label="Дистанция, м" v-model="dialogTimesDistance"></v-text-field>
                    <v-text-field placeholder="" hide-details type="number" min="0" label="Время забора, мин." v-model="dialogTimesTake"></v-text-field>
                    <v-text-field placeholder="" hide-details type="number" min="0" label="Время доставки, мин." v-model="dialogTimesDelivery"></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="onClickTimesSave">Сохранить</v-btn>
                    <v-btn v-if="dialogTimesChange" class="mx-2" color="error" text @click="onClickTimesDelete">Удалить</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogTimes = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogProfile" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Редактирование профиля
                </v-card-title>

                <v-card-text class="py-4">
                    <v-text-field placeholder="" hide-details label="Название" v-model="dialogProfileName"></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="onClickProfileNameSave">Сохранить</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogProfile = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import config from '../../config'
    import draggable from 'vuedraggable'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import SubscribeTable from './SubscribeTable.vue'
    import Notify from '../../mixins/Notify.js'

    export default {
        components: {
            draggable,
            SubscribeTable,
        },
        mixins: [Notify],
        data() {
            return {
                test_address_result: '',
                updateCoordinatesFlag: false,
                errorSave: false,
                needSave: false,
                saveTimer: 0,
                points: [],
                address: '',
                prices_full: [[], [], [], [], []],
                settings: {
                    prices: [[], [], [], [], []],
                },
                price_courier_default: 250,
                price_point_default: 250,
                times_take_default: 25,
                times_delivery_default: 25,
                newPoints: [],
                pointUpdateStep: 0,
                allPointsFlag: false,
                savePointsFlag: false,
                subscribeSaveFlag: false,
                sms_id: null,
                tab_point: null,
                tab_review: null,
                tab_subscribe: null,
                tab_position: null,
                pass1_show: false,
                pass2_show: false,
                pass3_show: false,
                pass4_show: false,
                pass5_show: false,
                pass1_value: '',
                pass2_value: '',
                pass3_value: '',
                updatePassFlag: false,
                settings_pass_show: false,
                settings_pass_value: '',
                settings_tz_value: '',
                settings_tz_string_value: '',
                settings_city_value: '',
                updateSettings: false,
                access: false,
                firstLoading: true,
                smsMessageRules: [v => v.length + 27 <= 70 || 'Максимум 70 символов'],
                subscribe_data: {},
                sms_types: ['sms_ru', 'sendmarket', 'smsc', 'sms_mts', 'mts_exolve'],
                sms_subscriber: '',
                sms_message: '',
                // sms_subscriber: '79024882705',
                // sms_message: 'Для отзыва переходи по ссылке ❤️ https://darall.pro/r#ehnsw',
                sms_balance: 0,
                sms_timer: null,
                current_point: null,
                qa_point_id: null,
                userIsPoint: false,
                loadingAccess: false,
                deliveryDelay: 0,
                updateMynexFlag: false,
                mynexLogin: '',
                mynexPass: '',
                quick_answers: [],
                review_page: false,
                review_page_text: '',
                checkQuickAnswers: false,
                color_background: ['rgba(175, 238, 238, 0.6)', 'rgba(255, 248, 220, 0.6)', 'rgba(127, 255, 212, 0.6)', 'rgba(255, 182, 193, 0.6)'],
                menu_time_start: false,
                menu_time_end: false,
                dialogPrice: false,
                dialogPriceIndex: 0,
                dialogPriceChange: false,
                dialogPriceHeader: '',
                dialogPriceDistance: 0,
                dialogPriceCourier: 0,
                dialogPricePoint: 0,
                dialogTimes: false,
                dialogTimesIndex: 0,
                dialogTimesChange: false,
                dialogTimesHeader: '',
                dialogTimesDistance: 0,
                dialogTimesTake: 0,
                dialogTimesDelivery: 0,
                dialogProfile: false,
                dialogProfileName: '',
                dialogProfileIndex: 0,
            }
        },
        mounted() {
            // console.log('start!')
            this.$store.state.settingsLock = true
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            if (this.userIsPoint) {
                this.points.push(this.$store.state.user.point)
                this.current_point = this.points[0]
                this.qa_point_id = this.current_point.id
                this.updateQuickAnswers()
            } else {
                this.init()
                // разрешаем sms таймер
                this.sms_timer = 1
            }
            const settingsUploadHandler = settings => {
                // console.log('settingsUploadHandler')
                // console.log('emit settings.prices', JSON.stringify(settings.prices))
                this.settings = JSON.parse(JSON.stringify(settings))
                // console.log('emit this.settings.prices', JSON.stringify(this.settings.prices))

                this.settingsSave()
                // this.saveTimer = 1
                setTimeout(() => {
                    // settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                    // this.init()
                    this.updatePrices(settings)
                    this.updateTimes(settings)
                }, 200)
            }
            this.$vm.$on('settings_upload', settingsUploadHandler)
        },
        created() {},

        beforeDestroy() {},

        destroyed() {
            if (this.timer) {
                clearInterval(this.timer)
            }
            if (this.sms_timer) {
                clearTimeout(this.sms_timer)
                this.sms_timer = null
            }
        },
        watch: {},
        computed: {},
        methods: {
            onClickMynexSave() {
                // console.log('onClickMynexSave')
                if (!this.mynexLogin || this.mynexLogin == '') {
                    this.notifyToast('Поле Логин необходимо заполнить', { color: 'error', timeout: 4000 })
                    return
                }
                if (!this.mynexPass || this.mynexPass == '') {
                    this.notifyToast('Поле Пароль необходимо заполнить', { color: 'error', timeout: 4000 })
                    return
                }
                this.updateMynexFlag = true

                // console.log('mynexLogin', this.mynexLogin)
                // console.log('mynexPass', this.mynexPass)

                this.$http.get(config.api.mynex + 'auth/login', { params: { name: this.mynexLogin, pass: this.mynexPass } }).then(res => {
                    // console.log('auth/login res.data', res.data)
                    if (res.data.success) {
                        const mynex_uid = res.data.uid
                        const mynex_series = res.data.series
                        const mynex_token = res.data.token
                        this.$store
                            .dispatch('user_get', this.$store.state.user.tid)
                            .then(res => {
                                if (res.data.success) {
                                    const user = res.data.user
                                    user.mynex_uid = mynex_uid
                                    user.mynex_series = mynex_series
                                    user.mynex_token = mynex_token
                                    this.$store
                                        .dispatch('user_set', user)
                                        .then(res => {
                                            if (res.data.success) {
                                                this.mynexLogin = ''
                                                this.mynexPass = ''
                                                this.notifyToast('Доступ изменен успешно.', { color: 'success', timeout: 4000 })
                                                // this.$store.commit('user', user)
                                            } else {
                                                this.notifyToast('Не удалось изменить доступ. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                            }
                                            this.updateMynexFlag = false
                                        })
                                        .catch(err => {
                                            this.notifyToast('Ошибка сохранения. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                            console.log(err)
                                            this.updateMynexFlag = false
                                        })
                                } else {
                                    this.notifyToast('Не удалось изменить доступ. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                    this.updateMynexFlag = false
                                }
                            })
                            .catch(err => {
                                this.notifyToast('Ошибка сохранения. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                console.log(err)
                                this.updateMynexFlag = false
                            })
                    }
                })
            },

            settingsSave(addText='') {
                return new Promise((resolve, reject) => {
                    this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                    this.$store
                        .dispatch('settings_set', this.settings)
                        .then(res => {
                            if (!res.data.success) {
                                this.notifyToast('Ошибка сохранения настроек. Попробуйте снова.', { color: 'error', timeout: 4000 })
                            } else {
                                this.notifyToast(`Настройки сохранены успешно. ${addText}`, { color: 'success', timeout: 4000 })
                                this.$store.commit('settings', this.settings)
                            }
                            resolve('ok')
                        })
                        .catch(err => {
                            this.notifyToast('Ошибка сохранения настроек. Попробуйте снова.', { color: 'error', timeout: 4000 })
                            console.log(err)
                            resolve('fail')
                        })
                })
            },

            onClickSettingsSave() {
                this.updateSettings = true
                this.settings.access_pass = this.settings_pass_value
                this.settings.tz = this.settings_tz_value
                this.settings.tz_string = this.settings_tz_string_value
                this.settings.city = this.settings_city_value
                this.settings.deliveryDelay = parseInt(this.deliveryDelay)
                this.settingsSave().then(res => {
                    this.updateSettings = false
                })
            },

            pointSelect(point) {
                this.current_point = point
                this.qa_point_id = this.current_point.id

                this.smsBalanceUpdate()
                this.updateQuickAnswers()
            },

            pointSelectId(event) {
                for (let i = 0; i < this.points.length; i++) {
                    if (this.points[i].id == event) {
                        this.tab_point = 'tab_point-' + i
                        this.pointSelect(this.points[i])
                        break
                    }
                }
            },

            smsServiceChange() {
                this.updatePoints()
            },

            msgBotServiceChange() {
                this.updatePoints()
            },

            smsBalanceUpdate() {
                if (this.current_point.settings.sms_enable) {
                    this.$store
                        .dispatch('sms_balance', {
                            point_id: this.current_point.id,
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.sms_balance = res.data.balance
                            } else {
                                this.sms_balance = '?'
                            }

                            if (this.sms_timer) {
                                clearTimeout(this.sms_timer)
                                this.sms_timer = null
                            }

                            this.sms_timer = setTimeout(() => {
                                this.smsBalanceUpdate()
                            }, 20000)
                        })
                }
            },

            sendMessage() {
                this.$store
                    .dispatch('sms_send', {
                        point_id: this.current_point.id,
                        phone: this.sms_subscriber,
                        text: this.sms_message,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.notifyToast(res.data.description, { color: 'warning', timeout: 4000 })
                            this.smsStatusCheck(res.data.message_id)
                        } else {
                            this.notifyToast('Сообщение не отправлено. ' + res.data.description, { color: 'error', timeout: 4000 })
                        }
                    })
                    .catch(err => {
                        this.notifyToast('Сообщение не отправлено.', { color: 'error', timeout: 4000 })
                        console.log(err)
                    })
            },

            smsStatusCheck(message_id) {
                setTimeout(() => {
                    this.$store
                        .dispatch('sms_status', {
                            point_id: this.current_point.id,
                            message_id,
                        })
                        .then(res => {
                            if (res.data.success) {
                                if (res.data.msg != 'ok') {
                                    this.notifyToast(res.data.description, { color: 'warning', timeout: 4000 })
                                    this.smsStatusCheck(message_id)
                                } else {
                                    this.notifyToast(res.data.description, { color: 'success', timeout: 4000 })
                                }
                            } else {
                                console.log('sms_status fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }, 2000)
            },

            validPhone(phone) {
                if (this.sms_subscriber.trim()) {
                    let re = /^\+?[78][ -(]?\d{3}[ -)]?\d{3}[ -]?\d{2}[ -]?\d{2}$/
                    if (!re.test(phone)) {
                        this.sms_subscriber = ''
                        this.notifyToast('Укажите корректный номер телефона.', { color: 'error', timeout: 4000 })
                    } else {
                        this.sms_subscriber = re.exec(phone)[0]
                    }
                }
            },

            onClickPassSave() {
                if (this.pass1_value && this.pass2_value && this.pass3_value) {
                    if (this.pass2_value == this.pass3_value) {
                        this.updatePassFlag = true
                        this.$store
                            .dispatch('user_get', this.$store.state.user.tid)
                            .then(res => {
                                if (res.data.success) {
                                    const user = res.data.user
                                    if (user.pass == this.pass1_value) {
                                        user.pass = this.pass2_value
                                        this.$store
                                            .dispatch('user_set', user)
                                            .then(res => {
                                                if (res.data.success) {
                                                    this.pass1_value = ''
                                                    this.pass2_value = ''
                                                    this.pass3_value = ''
                                                    this.notifyToast('Пароль изменен успешно.', { color: 'success', timeout: 4000 })
                                                } else {
                                                    this.notifyToast('Не удалось изменить пароль. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                                }
                                                this.updatePassFlag = false
                                            })
                                            .catch(err => {
                                                this.notifyToast('Ошибка сохранения пароля. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                                console.log(err)
                                                this.updatePassFlag = false
                                            })
                                    } else {
                                        this.updatePassFlag = false
                                        this.notifyToast('Неверный текущий пароль. Попробуйте еще раз.', { color: 'warning', timeout: 4000 })
                                    }
                                } else {
                                    this.notifyToast('Не удалось изменить пароль. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                    this.updatePassFlag = false
                                }
                            })
                            .catch(err => {
                                this.notifyToast('Ошибка сохранения пароля. Попробуйте снова.', { color: 'error', timeout: 4000 })
                                console.log(err)
                                this.updatePassFlag = false
                            })
                    } else {
                        this.notifyToast('Новые пароли не совпадают. Попробуйте еще раз.', { color: 'warning', timeout: 4000 })
                    }
                } else {
                    this.notifyToast('Необходимо заполнить все поля.', { color: 'info', timeout: 4000 })
                }
            },

            onClickSettingsPassEnter() {
                this.loadingAccess = true
                setTimeout(() => {
                    if (this.settings.access_pass == this.settings_pass_value) {
                        this.notifyToast('Настройки открыты успешно.', { color: 'success', timeout: 4000 })
                        this.access = true
                        this.loadingAccess = false
                        this.$store.state.settingsLock = false
                    } else {
                        this.notifyToast('Введен неверный пароль.', { color: 'error', timeout: 4000 })
                        this.loadingAccess = false
                        this.$store.state.settingsLock = true
                    }
                }, 250)
            },

            onSelectAddress() {
            },

            onClickAddress(address) {
                this.address = address
            },

            onClickPriceGet() {
                this.test_address_result = 'поиск...'
                this.$store
                    .dispatch('address_to_geofence', this.address)
                    .then(res => {
                        if (res.data.success) {
                            const geofence = res.data.geofence

                            if (!geofence.name) {
                                geofence.name = 'Без имени'
                            }

                            this.test_address_result = `Геозона: ${geofence.name}`

                            let i = 0
                            for (let price of geofence.price) {
                                geofence.price[i] = price ? price : '-'
                                this.test_address_result += `, Цена ${i + 1}: ${price}`
                                i++
                            }
                        } else {
                            this.test_address_result = `Геозона не найдена, Цена: ${this.settings.price_courier_default}`
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            updatePrices(settings) {
                if (settings && settings.prices) {
                    if (this.settings && this.settings.prices) {

                        const date_server_is_last = settings.date_update || moment(settings.date_update).isAfter(this.settings.date_update)
                        if (date_server_is_last) {
                            this.settings = settings
                            this.$store.commit('settings', JSON.parse(JSON.stringify(settings)))
                        } else {
                            this.saveTimer = config.settings.savetime
                        }
                    } else {
                        this.settings = settings
                        this.$store.commit('settings', JSON.parse(JSON.stringify(settings)))
                    }
                    for (let price of this.settings.prices) {
                        price.sort(this.sortPoints)
                    }
                    if (this.points.length) {
                        this.sms_id = this.points[0].settings.sms_id
                    } else {
                        this.sms_id = -1
                    }
                    let change = false

                    // фильтр несуществующих точек
                    for (let i = 0; i < this.settings.prices.length; i++) {
                        this.settings.prices[i] = this.settings.prices[i].filter(price_point_id => {
                            for (let point of this.points) {
                                if (price_point_id == point.id) {
                                    return true
                                }
                            }
                            change = true
                            return false
                        })
                    }
                    // фильтр дублирующихся точек
                    for (let i = 0; i < this.settings.prices.length; i++) {
                        let points_set = new Set()
                        for (let point_id of this.settings.prices[i]) {
                            points_set.add(point_id)
                        }
                        this.settings.prices[i] = this.settings.prices[i].filter(point_id => points_set.has(point_id) && points_set.delete(point_id))
                    }

                    let prices = []

                    if (config.geofence.price.length > this.settings.prices.length) {
                        for (let i = 0; i < config.geofence.price.length - this.settings.prices.length; i++) {
                            this.settings.prices.push([])
                            change = true
                        }
                    }
                    prices = [...this.settings.prices[0], ...this.settings.prices[1], ...this.settings.prices[2], ...this.settings.prices[3], ...this.settings.prices[4]]
                    for (let i = 0; i < this.settings.prices.length; i++) {
                        this.prices_full[i] = []
                        for (let point_id of this.settings.prices[i]) {
                            const point = this.points.find(point => point.id == point_id)
                            this.prices_full[i].push(point)
                        }
                    }
                    let points_new = this.points.filter(point => {
                        for (let point_id of prices) {
                            if (point.id == point_id) {
                                return false
                            }
                        }
                        return true
                    })
                    this.newPoints = points_new
                    if (!this.settings.distancePrices) {
                        this.settings.distancePrices = []
                        this.settings.distancePrices.push([0, config.distance_price.courier, config.distance_price.point])
                        change = true
                    }

                    if (!this.settings.distanceTimes) {
                        this.settings.distanceTimes = []
                        this.settings.distanceTimes.push([0, config.distance_times.take, config.distance_times.delivery])
                        change = true
                    }

                    if (change) {
                        this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                        this.$store.commit('settings', this.settings)
                        this.saveTimer = config.settings.savetime
                    }
                } else {
                    this.settings.prices = []
                    this.prices_full = []
                    for (let price of config.geofence.price) {
                        this.settings.prices.push([])
                        this.prices_full.push([])
                    }
                    this.newPoints = this.points.sort(this.sortPoints)
                    this.settings.price_courier_default = this.price_courier_default
                    this.settings.price_point_default = this.price_point_default
                    this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')

                    this.settings.times_courier_default = this.price_courier_default
                    this.settings.times_point_default = this.price_point_default

                    this.$store.commit('settings', this.settings)
                    this.saveTimer = config.settings.savetime
                }
            },

            onClickProfileSelect(index) {
                const selectedProfile = this.settings.distanceProfiles.find(profile => profile.isSelected)
                const profile = this.settings.distanceProfiles[index]
                profile.timesByDistance = profile.distanceTimes ?? profile.timesByDistance
                profile.priceByDistance = profile.distancePrices ?? profile.priceByDistance
                profile.distanceTimes = undefined
                profile.distancePrices = undefined

                if (selectedProfile.name === profile.name) {
                    return
                }

                selectedProfile.isSelected = false
                profile.isSelected = true

                this.settings.distanceTimes = profile.timesByDistance
                this.settings.distancePrices = profile.priceByDistance
            },

            onClickProfileCopy() {
                const selectedProfile = this.settings.distanceProfiles.find(profile => profile.isSelected)
                selectedProfile.isSelected = false
                const distanceTimes = JSON.parse(JSON.stringify(this.settings.distanceTimes))
                const distancePrices = JSON.parse(JSON.stringify(this.settings.distancePrices))
                let count = Number(this.settings.distanceProfiles[this.settings.distanceProfiles.length - 1].name.replace(/\D+/g, ''))
                const profile = {
                    name: `${selectedProfile.name} (${this.settings.distanceProfiles.length + 1})`,
                    isSelected: true,
                    timesByDistance: distanceTimes,
                    priceByDistance: distancePrices,
                }
                this.settings.distanceProfiles.push(profile)

                this.settings.distanceTimes = distanceTimes
                this.settings.distancePrices = distancePrices
            },

            onClickProfileDelete(index) {
                const selectedProfile = this.settings.distanceProfiles.find(profile => profile.isSelected)
                if (selectedProfile.name === this.settings.distanceProfiles[index].name) {
                    return
                }

                this.settings.distanceProfiles.splice(index, 1)
            },
            
            onClickProfileEdit(index) {
                this.dialogProfileIndex = index
                this.dialogProfileName = this.settings.distanceProfiles[this.dialogProfileIndex].name
                this.dialogProfile = true
            },
            
            onClickProfileNameSave() {
                this.settings.distanceProfiles[this.dialogProfileIndex].name = this.dialogProfileName
                this.dialogProfile = false
            },

            init() {
                setTimeout(() => {
                    this.$store.state.loading = true
                })
                this.timer = setInterval(() => {
                    if (this.saveTimer) {
                        this.saveTimer--
                        if (this.saveTimer == 0) {
                            for (let i = 0; i < this.settings.prices.length; i++) {
                                this.settings.prices[i] = []
                                for (let point of this.prices_full[i]) {
                                    this.settings.prices[i].push(point.id)
                                }
                            }
                            this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                            this.$store
                                .dispatch('settings_set', this.settings)
                                .then(res => {
                                    if (!res.data.success) {
                                        console.log('settings saved fail')
                                        this.errorSave = true
                                        this.saveTimer = config.settings.savetime
                                    } else {
                                        // console.log('settings saved ok')
                                        this.errorSave = false
                                        this.$store.commit('settings', this.settings)
                                    }
                                })
                                .catch(err => {
                                    this.errorSave = true
                                    this.saveTimer = config.settings.savetime
                                    console.log(err)
                                })
                        }
                    }
                }, 100)

                this.settings = JSON.parse(JSON.stringify(this.$store.state.settings))
                this.deliveryDelay = this.settings.deliveryDelay
                this.settings_tz_value = this.settings.tz
                this.settings_tz_string_value = this.settings.tz_string
                this.settings_city_value = this.settings.city

                if (!this.settings) {
                    this.settings = {}
                }
                this.$store
                    .dispatch('settings_get')
                    .then(res => {
                        if (res.data.success) {
                            console.log('load settings from server')
                            const settings = res.data.settings

                            if (!settings.distanceProfiles || settings.distanceProfiles.length === 0) {
                                let distanceTimes = [[0, 30, 30]]
                                let distancePrices = [[0, 315, 315]]
                                if (settings.distanceTimes) {
                                    distanceTimes = settings.distanceTimes
                                }
                                if (settings.distancePrices) {
                                    distancePrices = settings.distancePrices
                                }

                                settings.distanceProfiles = [{
                                    name: 'Профиль по умолчанию',
                                    isSelected: true,
                                    // distanceTimes,
                                    // distancePrices,
                                    timesByDistance: distanceTimes,
                                    priceByDistance: distancePrices,
                                }]
                            }

                            this.$store
                                .dispatch('points_get')
                                .then(res => {
                                    this.$store.state.loading = false
                                    if (res.data.success) {
                                        this.points = res.data.points.sort(this.sortPoints)
                                        this.updatePrices(settings)
                                        for (let point of this.points) {
                                            if (!point.settings.sms_text_review) {
                                                point.settings.sms_text_review = 'Для отзыва переходи по ссылке'
                                            }
                                            if (!point.settings.sms_text_position) {
                                                point.settings.sms_text_position = 'Смотри где заказ по ссылке'
                                            }
                                            if (!point.settings.name_style) {
                                                point.settings.name_style = 'default'
                                            }
                                        }
                                        this.current_point = this.points[0]
                                        this.qa_point_id = this.current_point.id

                                        this.updateQuickAnswers()

                                        this.smsBalanceUpdate()
                                    }
                                })
                                .catch(err => {
                                    console.log('dispatch points_get err', err.stack)
                                    this.$store.state.loading = false
                                })
                            if (settings.access_pass && settings.access_pass != '') {
                                this.access = false
                            } else {
                                this.access = true
                            }
                            if (!settings.subscribes) {
                                this.subscribe_data = []
                            } else {
                                this.subscribe_data = settings.subscribes
                            }
                            this.firstLoading = false

                            this.deliveryDelay = settings.deliveryDelay
                            if (settings.tz) {
                                this.settings_tz_value = settings.tz
                            } else {
                                this.settings_tz_value = this.$store.state.settings.tz
                            }
                            if (settings.tz_string) {
                                this.settings_tz_string_value = settings.tz_string
                            } else {
                                this.settings_tz_string_value = this.$store.state.settings.tz_string
                            }
                            if (settings.city) {
                                this.settings_city_value = settings.city
                            } else {
                                this.settings_city_value = this.$store.state.settings.city
                            }
                        } else {
                            this.$store.state.loading = false
                            console.error('не удалось загрузить настройки с сервера')
                        }
                    })
                    .catch(err => {
                        console.log('err', err.stack)
                        this.$store.state.loading = false
                    })
            },
            onChangeSubscribeData: function (subscribes) {
                // this.subscribe_data = subscribes
                this.subscribe_data = subscribes
                this.settings.subscribes = subscribes
                this.onChangeSubscribe(subscribes)
            },
            onChangeServices:function(evt){
                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.settings.savetime
                this.settingsSave('Страница будет перезагружена через 3 сек.')
                .then(res=>setTimeout(()=>{this.$router.go()},3000))
            },
            onChangeSubscribe: function (evt) {
                // console.log(evt)
                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.settings.savetime
                this.savePointsFlag = true
            },
            onChangePrice: function (evt) {
                // console.log(evt)
                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.settings.savetime
            },
            onChangeRoute: function (evt) {
                // console.log(evt)
                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.settings.savetime
            },
            onChangeTimes: function (evt) {
                // console.log(evt)
                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.settings.savetime
            },
            onChangeSettings: function (evt) {
                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
            },
            onChangePriceDefault() {
                // console.log('onChangePriceDefault')

                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.settings.savetime
            },
            onClickPriceChange(index) {
                if (index < this.settings.distancePrices.length) {
                    this.dialogPrice = true
                    this.dialogPriceIndex = index
                    this.dialogPriceChange = true
                    this.dialogPriceHeader = 'Изменение дистанции'
                    this.dialogPriceDistance = this.settings.distancePrices[index][0]
                    this.dialogPriceCourier = this.settings.distancePrices[index][1]
                    this.dialogPricePoint = this.settings.distancePrices[index][2]
                }
            },
            onClickPriceAdd() {
                this.dialogPrice = true
                this.dialogPriceChange = false
                this.dialogPriceHeader = 'Добавление дистанции'
                this.dialogPriceDistance = null
                this.dialogPriceCourier = null
                this.dialogPricePoint = null
            },
            onClickPriceSave() {
                this.dialogPrice = false
                if (this.dialogPriceChange) {
                    this.settings.distancePrices[this.dialogPriceIndex] = [+this.dialogPriceDistance, +this.dialogPriceCourier, +this.dialogPricePoint]
                } else {
                    this.settings.distancePrices.push([+this.dialogPriceDistance, +this.dialogPriceCourier, +this.dialogPricePoint])
                }
                this.settings.distancePrices.sort(this.sortPriceByDistance)
            },
            onClickPriceDelete() {
                this.dialogPrice = false
                if (this.dialogPriceIndex < this.settings.distancePrices.length) {
                    this.settings.distancePrices.splice(this.dialogPriceIndex, 1)
                }
            },

            onChangeTimesDefault() {
                // console.log('onChangeTimesDefault')

                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.settings.savetime
            },
            onClickTimesChange(index) {
                if (index < this.settings.distanceTimes.length) {
                    this.dialogTimes = true
                    this.dialogTimesIndex = index
                    this.dialogTimesChange = true
                    this.dialogTimesHeader = 'Изменение дистанции'
                    this.dialogTimesDistance = this.settings.distanceTimes[index][0]
                    this.dialogTimesTake = this.settings.distanceTimes[index][1]
                    this.dialogTimesDelivery = this.settings.distanceTimes[index][2]
                }
            },
            onClickTimesAdd() {
                this.dialogTimes = true
                this.dialogTimesChange = false
                this.dialogTimesHeader = 'Добавление дистанции'
                this.dialogTimesDistance = null
                this.dialogTimesTake = null
                this.dialogTimesDelivery = null
            },
            onClickTimesSave() {
                this.dialogTimes = false
                if (this.dialogTimesChange) {
                    this.settings.distanceTimes[this.dialogTimesIndex] = [+this.dialogTimesDistance, +this.dialogTimesTake, +this.dialogTimesDelivery]
                } else {
                    this.settings.distanceTimes.push([+this.dialogTimesDistance, +this.dialogTimesTake, +this.dialogTimesDelivery])
                }
                this.settings.distanceTimes.sort(this.sortTimesByDistance)
            },
            onClickTimesDelete() {
                this.dialogTimes = false
                if (this.dialogTimesIndex < this.settings.distanceTimes.length) {
                    this.settings.distanceTimes.splice(this.dialogTimesIndex, 1)
                }
            },

            onClickSave() {
                this.saveTimer = 1
            },
            onClickSubscribeSave() {
                if (this.saveTimer) {
                    this.saveTimer = 1
                }
                if (this.savePointsFlag) {
                    this.onClickSavePoints()
                }
            },
            onChangeAddress() {
            },
            sortPoints(a, b) {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                if (a.address > b.address) {
                    return 1
                }
                if (a.address < b.address) {
                    return -1
                }
                return 0
            },
            sortPriceByDistance(a, b) {
                if (a[0] > b[0]) {
                    return 1
                }
                if (a[0] < b[0]) {
                    return -1
                }
                return 0
            },
            sortTimesByDistance(a, b) {
                if (a[0] > b[0]) {
                    return 1
                }
                if (a[0] < b[0]) {
                    return -1
                }
                return 0
            },
            async onClickSavePoints() {
                this.$store.state.loading = true
                this.$store.dispatch('points_get').then(res => {
                    if (res.data.success) {
                        let points = res.data.points
                        for (let point of this.points) {
                            if (point.settings.subscribe_enable && point.settings.subscribe_id) {
                                let subscribe
                                if (this.$store.state.settings.subscribe_enable) {
                                    subscribe = this.$store.state.settings.subscribes.find(s => s.id == point.settings.subscribe_id)
                                }
                                if (subscribe) {
                                    let sms_cost = point.settings.sms_count_curr - subscribe.count
                                    if (sms_cost > 0) {
                                        sms_cost = sms_cost * subscribe.cost
                                    } else {
                                        sms_cost = 0
                                    }
                                    point.settings.subscribe_cost_curr = +subscribe.price + sms_cost
                                    const p = points.find(p => p.id == point.id)
                                    if (p) {
                                        point.settings.sms_count_curr = p.settings.sms_count_curr // чтобы не потерять накопленные смс за время редактирования настроек
                                    }
                                }
                            }
                            if (this.allPointsFlag) {
                                point.settings.sms_type = this.current_point.settings.sms_type
                                point.settings.sms_id = this.current_point.settings.sms_id
                                point.settings.sms_login = this.current_point.settings.sms_login
                                point.settings.sms_password = this.current_point.settings.sms_password
                                point.settings.sms_name = this.current_point.settings.sms_name
                                point.settings.msgBotEnabled = this.current_point.settings.msgBotEnabled
                                point.settings.msgBotType = this.current_point.settings.msgBotType
                                point.settings.msgBotToken = this.current_point.settings.msgBotToken
                            }
                        }
                        const points_count = this.points.length
                        let success_timer = null
                        for (let i = 0; i < points_count; i += 20) {
                            const part_points = this.points.slice(i, i + 20)
                            this.$store.dispatch('points_set', part_points).then(res => {
                                if (res.data.success) {
                                    this.savePointsFlag = false
                                    if (success_timer) {
                                        clearTimeout(success_timer)
                                        success_timer = null
                                    }
                                    success_timer = setTimeout(() => {
                                        if (this.sms_timer) {
                                            clearTimeout(this.sms_timer)
                                        }
                                        this.sms_balance = 0
                                        this.smsBalanceUpdate()

                                        this.savePointsFlag = false
                                        this.notifyToast('Настройки точек сохранены успешно.', { color: 'success', timeout: 4000 })
                                    }, 1000)
                                } else {
                                    this.notifyToast('Ошибка сохранения настроек.', { color: 'error', timeout: 4000 })
                                }
                                this.$store.state.loading = false
                            })
                        }
                    } else {
                        this.$store.state.loading = false
                        this.notifyToast('Ошибка сохранения настроек.', { color: 'error', timeout: 4000 })
                    }
                })
            },
            updateSmsPoint(sms_id) {
                this.sms_id = sms_id
                this.onClickSavePoints()
            },
            updatePoints() {
                this.savePointsFlag = true
            },
            updateSms(p) {
                for (let point of this.points) {
                    point.settings.sms_name = p.sms_name
                    point.settings.sms_id = p.sms_id
                }
                this.savePointsFlag = true
            },
            quickAnswerCreate() {
                const quick_answer = {
                    id: 0,
                    name: 'Быстрый ответ ' + (this.quick_answers.length + 1) + '.',
                    point_id: this.current_point.id,
                    food: 0,
                    delivery: 0,
                    isShowed: 1,
                }
                this.$store
                    .dispatch('quick_answer_create', quick_answer)
                    .then(res => {
                        if (res.data.success) {
                            quick_answer.id = res.data.quickAnswer.id
                            this.quick_answers.push(quick_answer)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            quickAnswerDelete(qa) {
                this.$store
                    .dispatch('quick_answers_delete', qa.id)
                    .then(res => {
                        if (res.data.success) {
                            for (let i = 0; i < this.quick_answers.length; i++) {
                                if (this.quick_answers[i].id === qa.id) {
                                    this.quick_answers.splice(i, 1)
                                }
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            quickAnswerChangeIsShowed(qa) {
                qa.isShowed = qa.isShowed ? 0 : 1
                this.quickAnswerUpdate(qa)
            },
            quickAnswerUpdate(qa) {
                qa.delivery = qa.delivery ? 1 : 0
                qa.food = qa.food ? 1 : 0
                this.$store
                    .dispatch('quick_answers_update', qa)
                    .then(res => {
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            updateQuickAnswers() {
                this.$store
                    .dispatch('quick_answers_get', this.qa_point_id)
                    .then(res => {
                        if (res.data.success) {
                            this.quick_answers = res.data.quickAnswers
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            clickQuickAnswer(qa) {
                qa.isPressed = qa.isPressed ? 0 : 1
                if (qa.isPressed) {
                    this.review_page_text += ' ' + qa.name + ' '
                } else {
                    let string = this.review_page_text
                    qa.name = ' ' + qa.name + ' '
                    string = string.replace(qa.name, '')
                    this.review_page_text = string
                }
            },
        },
    }
</script>

<style lang="stylus">

    .profile-clickable:hover
        cursor pointer
        background-color #F9FCCF

    .t-distance
        td
            padding 3px
        th
            padding 2px
        .t-button
            cursor pointer
            border-radius 4px
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
            &.t-green
                color white
                background-color #4CAF50
            &.t-blue
                color white
                background-color #2196F3
            &.t-red
                color white
                background-color #e96f7e
            &.t-disabled,.t-button:hover
                color white
                cursor default
                background-color #ddd
                box-shadow: none
        .t-button:hover
            transition 0.5s cubic-bezier(0.540, 1.410, 0.540, 0.870)
            &.t-green
                background-color #66BB6A
            &.t-blue
                background-color #42A5F5
            &.t-red
                background-color #FF8080

    .settings
        height 100%
        padding-top 10px !important

        a
            text-decoration none

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                .v-data-table__expanded__row:hover
                    background #e4e4e4 !important
                    cursor pointer !important

                td
                    font-size 0.75rem
                    padding 0
                    padding-left 16px
                    p
                        margin-top 6px
                        margin-bottom 6px



        .v-list
            overflow-y auto
            height 216px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                cursor default !important

        .th-first-item
            width 30%


        .v-alert
            width 450px
            position fixed
            display flex
            top 10px
            margin 0 auto
            z-index 10


    .left-align
        text-align left


    .settings-content
        padding: 5px 30px;
        text-align left

    .settings-test
        background-color #feffe4
    .review-page
        text-align: center;
        .btn-page {
            cursor: pointer;
            min-width: 200px;
            padding: 15px 5px;
            text-transform: uppercase;
            vertical-align: middle;
            background: #3da35a;
            border: none;
            color: #ffffff;
            font-weight: 400;
            font-size: 18px;
            border-radius: 5px;
            box-shadow: 0 0 10px rgb(56, 100, 38);
            transition: all 1s;
        }
        .quick-answers {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .quick-answers button{
            margin-left: 5px;
            margin-bottom: 5px;
            box-shadow: 0 0 0 black;
            width: auto;
            height: 30px;
            text-transform: none;
            font-size:small;
            transition-duration: 0.01s;
            padding: 3px 3px;
        }
        .quick-answers button:hover{
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
        }

    .checkQuickAnswers {
        margin-top: 10px;
    }
</style>
